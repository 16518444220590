<template>
  <div class="container-fluid">
    <div id="modalLabel" class="box-title">{{ $t('message.privacypolicy') }}</div>
    <div class="divider"/>
    <div class="box-body">
      <div>
        <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span style="font-size: 16px;font-weight: bold">Beef to China Privacy Statement</span></div>
        <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>Beef to China recognizes the importance of the privacy issue in use of the Internet and World Wide Web. Information which you provide to us either directly (through on-line forms or via e-mail) or indirectly (data normally collected on traffic to this site) is covered by our Privacy Policy.</span></div>
        <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span style="font-size: 16px;font-weight: bold">Beef to China Privacy Policy</span></div>
        <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);"><span>Beef to China holds in confidence all information (such as name, address, and phone number) given us by customers and visitors to this site. We will not sell or release this information to any organization outside of Beef to China without your knowledge and consent. Information you provide is used to support your customer relationship with Beef to China. You may at any time ask for more information about our privacy policy by sending email to info@beeftochina.com. We reserve the right to retain certain customer information, which relates to account status, User content and documents. </span></div>
      </div>   </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  components: {},
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.container-fluid {
  width: 1200px;
  min-height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 2048px) {
  .container-fluid {
    min-height: 835px;
  }
}
.box-title {
  text-align: center;
  color: #333333;
  font-size: 20px;
  margin-top: 40px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #F5F5F5;
  margin-top: 20px;
}

.box-body {
  margin-bottom: 20px;
}

.box-body div {
  margin: 20px 0;
  font-size: 14px;
  color: #666666;
}

.box-body div span {
  font-size: 16px;
}
</style>
